import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import AlertComponent from "../components/alertBox";
import Loader from "../Ui/loader";
import TextInput from "../Ui/input";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function ReportPost() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [reportType, setReportType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const menuItems = [
    { value: 10, label: "It's spam" },
    { value: 20, label: "Nudity or sexual content" },
    { value: 30, label: "Hate speech" },
    { value: 40, label: "Violence content" },
    { value: 50, label: "Dangerous content" },
    { value: 60, label: "Sale of illegal goods" },
    { value: 70, label: "Intellectual property" },
    { value: 80, label: "Drugs" },
    { value: 90, label: "False information" },
    { value: 100, label: "Scam or fraud" },
  ];

  const location = useLocation();
  const { author, postName } = location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || reportType === "") {
      setError(true);
      setErrorMessage("Please fill out all the fields.");

      return;
    }
    setError(false);
    setErrorMessage("");
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/report`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          query,
          reportType,
          author,
          postName,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Thank you for Reporting!");
      } else {
        setErrorMessage("Failed To send Your Report.");
      }
    } catch (error) {
      setErrorMessage("Error submitting form: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <>
        <Helmet>
          <title>Report: {postName}</title>
        </Helmet>
        {loading && <Loader />}
        <AlertComponent status="success" message={successMessage} />
        <AlertComponent status="error" message={errorMessage} />
        <form className="report" onSubmit={handleSubmit}>
          <h1>Report Content</h1>

          <TextInput value={author} label="Author Name" disabled />

          <TextInput value={postName} label="Post Title" disabled />

          <TextInput
            value={name}
            label="Your Name"
            onChange={(ev) => {
              setName(ev.target.value);
              setError(false);
            }}
            error={error && !name}
            helperText={error && !name ? "Please enter your Name" : ""}
          />

          <TextInput
            value={email}
            label="Email"
            onChange={(ev) => {
              setEmail(ev.target.value);
              setError(false);
            }}
            error={error && !email}
            helperText={error && !email ? "Please enter your Email" : ""}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl margin="dense" size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reportType}
                label="Reason"
                onChange={(ev) => {
                  setReportType(ev.target.value);
                  setError(false);
                }}
                error={error && !reportType}
                helperText={
                  error && !reportType
                    ? "Please select a Reason for reporting"
                    : ""
                }
              >
                {menuItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <TextInput
            value={query}
            label="Further Mentions (Optional)"
            onChange={(ev) => {
              setQuery(ev.target.value);
            }}
          />
          <Button
            endIcon={<SendIcon sx={{ color: "white" }} />}
            style={{ marginTop: "5px" }}
            fullWidth
            variant="contained"
            type="submit"
          >
            Submit
          </Button>

          <div className="report-div">
            <h3>Reporting Guidelines</h3>
            <p>
              If you believe content violates our guidelines, please report it.
              We’ll review each report carefully. Your help ensures a safe,
              respectful environment for all
            </p>
          </div>
        </form>
      </>
    </>
  );
}
