import { Helmet } from "react-helmet";
import { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../components/userContext";
import AlertComponent from "../components/alertBox";
import Loader from "../Ui/loader";
import TextInput from "../Ui/input";
import { Button, IconButton, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

export default function ChangePassword() {
  const [identifier, setIdentifier] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfrimPassword] = useState("");
  const { setUserInfo } = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (username, password) => {
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.ok) {
      const userInfo = await response.json();
      document.cookie = `token=${userInfo.token}; path=/`;
      setUserInfo(userInfo);
      setSuccessMessage("Password updated");
      setRedirect(true);
    } else {
      const errorMessage = await response.text();
      console.log(errorMessage);
      setErrorMessage("Failed to update");
    }
  };

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setError(false);
    setErrorMessage("");
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/ResetPassword`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, newPassword }),
      });

      if (!response.ok) {
        setErrorMessage("Failed to update password");
      } else {
        setSuccessMessage("Password successfully updated.");
        await handleLogin(identifier, newPassword);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      {loading && <Loader />}
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="login" onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        <TextInput
          value={identifier}
          placeholder="Username or Email"
          onChange={(ev) => {
            setIdentifier(ev.target.value);
            setError(false);
          }}
          error={error && !identifier}
          helperText={
            error && !identifier ? "Please enter username or email" : ""
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">
                  account_circle
                </span>
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={newPassword}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={(ev) => {
            setNewPassword(ev.target.value);
            setError(false);
          }}
          error={error && !newPassword}
          helperText={error && !newPassword ? "Please enter Password" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">lock</span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-outlined">
                    {showPassword ? "visibility_off" : "visibility"}
                  </span>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={confirmPassword}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={(ev) => {
            setConfrimPassword(ev.target.value);
            setError(false);
          }}
          error={error && !confirmPassword}
          helperText={
            error && !confirmPassword ? "Please Re-enter Password" : ""
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">lock</span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-outlined">
                    {showPassword ? "visibility_off" : "visibility"}
                  </span>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          endIcon={<SendIcon sx={{ color: "white" }} />}
          fullWidth
          variant="contained"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </>
  );
}
