import { useState, useContext } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { UserContext } from "../components/userContext";
import { Helmet } from "react-helmet";
import TextInput from "../Ui/input";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AlertComponent from "../components/alertBox";
import { Button, IconButton, InputAdornment } from "@mui/material";
import Loader from "../Ui/loader";
import SendIcon from "@mui/icons-material/Send";

export default function LoginPage() {
  const navigate = useNavigate();
  const navigateToRegister = () => navigate("/register");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { setUserInfo } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  async function login(ev) {
    ev.preventDefault();
    if (!username || !password) {
      setError(true);
      setErrorMessage("Please fill out all the fields.");
      return;
    }

    setError(false);
    setErrorMessage("");
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/login`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === "401") {
          setErrorMessage("Account not found");
        } else if (errorData.error === "402") {
          setErrorMessage("Wrong password");
        } else {
          setErrorMessage("Server error, sorry it's our fault");
        }
      } else {
        const userInfo = await response.json();
        if (rememberMe) {
          localStorage.setItem("token", userInfo.token);
        } else {
          localStorage.removeItem("token");
        }
        setUserInfo(userInfo);
        setRedirect(true);
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Network error. Please try again later.");
    } finally {
      setLoading(false);
    }
  }

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {loading && <Loader />}
      <AlertComponent status="error" message={errorMessage} />

      <form className="login" onSubmit={login}>
        <h1>Welcome Back!</h1>
        <TextInput
          value={username}
          placeholder="Username or Email"
          onChange={(ev) => {
            setUsername(ev.target.value);
            setError(false);
          }}
          error={error && !username}
          helperText={
            error && !username ? "Please enter username or email" : ""
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">
                  account_circle
                </span>
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={password}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={(ev) => {
            setPassword(ev.target.value);
            setError(false);
          }}
          error={error && !password}
          helperText={error && !password ? "Please enter Password" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">lock</span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-outlined">
                    {showPassword ? "visibility_off" : "visibility"}
                  </span>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link to="/ResetPassword">Forgot password?</Link>
        <Button
          endIcon={<SendIcon sx={{ color: "white" }} />}
          fullWidth
          variant="contained"
          type="submit"
        >
          Login
        </Button>

        <FormControlLabel
          isInvalid
          onChange={(e) => setRememberMe(e.target.checked)}
          isChecked={rememberMe}
          control={
            <Checkbox
              defaultChecked
              sx={{ fontSize: "small", color: "white" }}
            />
          }
          label="Remember me"
        />
        <p>New to Blogstera?</p>
        <Button
          onClick={navigateToRegister}
          fullWidth
          variant="contained"
          type="submit"
        >
          Create account
        </Button>
      </form>
    </>
  );
}
