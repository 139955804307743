import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Post from "../components/post";
import Footer from "../components/footer";
import { Button } from "@mui/material";
import Loader from "../Ui/loader";
import { Helmet } from "react-helmet";

export default function Explore() {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(
    new URLSearchParams(location.search).get("category") || "Trending"
  );
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async (category) => {
    setLoading(true);
    try {
      let url =
        category === "Trending"
          ? `${process.env.REACT_APP_API_URL}/posts/latest`
          : `${process.env.REACT_APP_API_URL}/posts/category/${category}`;
      const response = await fetch(url);
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(selectedCategory);
  }, [selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <Helmet>
        <title>Explore</title>
      </Helmet>
      {loading && <Loader />}
      <div className="explore-main">
        <div className="explore-container">
          {[
            "Trending",
            "News",
            "Business",
            "Technology",
            "Sports",
            "Entertainment",
            "Gaming",
            "Opinions",
            "Science",
            "Health",
            "Travel",
            "Food",
          ].map((category) => (
            <div className="explore-box" key={category}>
              <Button
                variant="text"
                onClick={() => handleCategoryClick(category)}
              >
                <span className="material-symbols-outlined">
                  {category === "Trending" && "trending_up"}
                  {category === "News" && "newspaper"}
                  {category === "Business" && "monitoring"}
                  {category === "Technology" && "phone_iphone"}
                  {category === "Sports" && "sports_soccer"}
                  {category === "Entertainment" && "movie"}
                  {category === "Gaming" && "sports_esports"}
                  {category === "Science" && "experiment"}
                  {category === "Opinions" && "campaign"}
                  {category === "Health" && "health_and_beauty"}
                  {category === "Travel" && "flight_takeoff"}
                  {category === "Food" && "fastfood"}
                </span>
                <p style={{ paddingLeft: "5px" }}>{category}</p>
              </Button>
            </div>
          ))}
        </div>

        <div className="grid-container">
          {loading ? null : posts.length > 0 ? (
            posts.map((post) => (
              <div key={post._id} className="grid-item">
                <Post {...post} />
              </div>
            ))
          ) : (
            <p>No posts found in this category</p>
          )}
        </div>
        {posts.length > 0 && !loading && <Footer />}
      </div>
    </>
  );
}
