import { useState } from "react";
import AlertComponent from "../components/alertBox";
import { Helmet } from "react-helmet";
import TextInput from "../Ui/input";
import { Button, InputAdornment } from "@mui/material";
import TextArea from "../Ui/textArea";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../Ui/loader";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !query) {
      setError(true);
      setErrorMessage("Please fill out all the fields.");
      return;
    }

    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/contact`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, query }),
      });

      if (response.ok) {
        setSuccessMessage("Thanks for your contact!");
        setName("");
        setEmail("");
        setQuery("");
        setError(false);
      } else {
        setErrorMessage("An error occurred while submitting the form.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="We're always eager to hear from you! Whether you have feedback, questions, business inquiries, or just want to say hello, please don't hesitate to get in touch with us. Your thoughts and concerns are important to us, and we're committed to providing you with the best possible support."
        />
      </Helmet>
      {loading && <Loader />}
      {successMessage && (
        <AlertComponent status="success" message={successMessage} />
      )}
      {errorMessage && <AlertComponent status="error" message={errorMessage} />}

      <form className="contact" onSubmit={handleSubmit}>
        <h1>Let's Connect!</h1>

        <TextInput
          value={name}
          placeholder="Name"
          onChange={(ev) => {
            setName(ev.target.value);
            setError(false);
          }}
          error={error && !name}
          helperText={error && !name ? "Please enter your name" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">
                  account_circle
                </span>
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={email}
          placeholder="Email@gmail.com"
          onChange={(ev) => {
            setEmail(ev.target.value);
            setError(false);
          }}
          error={error && !email}
          helperText={error && !email ? "Please enter your email" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">mail</span>
              </InputAdornment>
            ),
          }}
        />

        <TextArea
          value={query}
          placeholder="Message"
          onChange={(ev) => {
            setQuery(ev.target.value);
            setError(false);
          }}
          error={error && !query}
          helperText={error && !query ? "Please enter a message" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">comment</span>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          variant="contained"
          endIcon={<SendIcon sx={{ color: "white" }} />}
          fullWidth
        >
          Send
        </Button>

        <div className="contact-div">
          <h3>Business Inquiries</h3>
          <p>
            Interested in partnering with us or have a business proposal? Please
            reach us out at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:blogsteratech@gmail.com"
            >
              blogsteratech@gmail.com
            </a>
          </p>
        </div>
      </form>
    </>
  );
}
