import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="home-index">
      <div className="navbar-toggle">
        <Link to="/Explore">
          <div className="gicon-title">
            <span class="material-symbols-outlined">explore</span>
            <p>Explore</p>
          </div>
        </Link>
        <div style={{ display: "flex", gap: "10px" }}>
          <Link to="/create">
            <div className="gicon-title">
              <span class="material-symbols-outlined">post_add</span>
            </div>
          </Link>
          <Link to="/contact">
            <div className="gicon-title">
              <span class="material-symbols-outlined">mail</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
