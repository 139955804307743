import * as React from "react";
import TextField from "@mui/material/TextField";

export default function TextInput(props) {
  return (
      <TextField 
      fullWidth size="small" margin="dense" variant="outlined" 
      {...props}
      />
  );
}
