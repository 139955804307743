import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";

const FileUploadField = ({ fileName, ...props }) => {
  return (
    <Box sx={{ marginTop: "5px" }}>
      <Button
        startIcon={<ImageIcon />}
        size="small"
        component="label"
      >
        Upload Image
        <input type="file" hidden {...props} />
      </Button>
    </Box>
  );
};

export default FileUploadField;
