import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

export default function SelectField({
  menuItems = [],
  placeholder = "Select an option",
  startAdornment,
  ...props
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl margin="dense" fullWidth>
        <Select
          size="small"
          displayEmpty
          input={
            <OutlinedInput
              startAdornment={
                startAdornment && (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                )
              }
            />
          }
          {...props}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
