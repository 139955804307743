import React, { forwardRef } from "react";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";

const Post = forwardRef(
  ({ slug, author, createdAt, title, summary, cover }, ref) => {
    const relativeTime = formatDistanceToNow(new Date(createdAt), {
      addSuffix: true,
    });

    return (
      <div ref={ref} key={slug} className="post">
        <div className="image">
          <Link to={`/post/${slug}`}>
            <img className="banner-img" src={cover} alt="" />
          </Link>
        </div>
        <div className="text">
          <Link to={`/post/${slug}`}>
            <h2>{title}</h2>
          </Link>
          <p className="summary">
            {summary}
            <Link to={`/post/${slug}`}>...Read more</Link>
          </p>

          <p className="info">
            <Link to={`/user/${author.username}`} className="author">
              {author.username}
            </Link>{" "}
            <time>{relativeTime}</time>
          </p>
        </div>
      </div>
    );
  }
);

export default Post;
