
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <footer>
        <div className="top-footer">
          <div className="footer-div">
            <h2>Sign up to our newsletter to receive updates!</h2>
            <hr></hr>
            <p>
              Don't miss out on the latest news and exciting developments.
              Subscribe now to receive newsletter!
            </p>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="footer-route">
            <ul className="footer-menu">
              <li>
                <Link to="/about">Policies</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link>© Blogstera 2024</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
