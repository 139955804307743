import { useState, useContext } from "react";
import { Navigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UserContext } from "../components/userContext";
import AlertComponent from "../components/alertBox";
import TextInput from "../Ui/input";
import { Button, IconButton, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../Ui/loader";
import SelectField from "../Ui/select";

export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [interestType, setInterestType] = useState("");
  const [password, setPassword] = useState("");
  const { setUserInfo } = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const menuItems = [
    { value: 10, label: "Business" },
    { value: 20, label: "News" },
    { value: 30, label: "Science" },
    { value: 40, label: "Technology" },
    { value: 50, label: "Entertainment" },
    { value: 60, label: "Sports" },
    { value: 70, label: "Health" },
    { value: 80, label: "Lifestyle" },
    { value: 90, label: "Travel" },
    { value: 100, label: "Food" },
    { value: 110, label: "Opinions" },
  ];

  const handleLogin = async (username, password) => {
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.ok) {
      const userInfo = await response.json();
      document.cookie = `token=${userInfo.token}; path=/`;
      setUserInfo(userInfo);
      setRedirect(true);
    } else {
      const errorMessage = await response.text();
      console.log(errorMessage);
    }
  };

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  async function register(ev) {
    ev.preventDefault();
    if (!username || !password) {
      setError(true);
      setErrorMessage("Please fill out all the fields.");
      return;
    }
    setError(false);
    setErrorMessage("");
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/register`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ username, password, email, interestType }),
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === "401") {
          setErrorMessage("Password must be at least 4 characters long");
        } else if (errorData.error === "402") {
          setErrorMessage("Username already exists");
        } else if (errorData.error === "403") {
          setErrorMessage("Email already exists");
        } else {
          setErrorMessage("Failed to create account");
        }
      } else {
        setSuccessMessage("Account created!");
        await handleLogin(username, password);
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Network error. Please try again later.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Create an account</title>
        <meta
          name="description"
          content="Sign up to blogstera today and unlock exclusive benefits! Sign up now to access premium content, receive updates, and connect with like-minded individuals. Start your journey with us and discover new opportunities to learn, grow, and thrive."
        />
      </Helmet>
      {loading && <Loader />}
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form class="login" onSubmit={register}>
        <h1>Create account</h1>
        <TextInput
          type="text"
          value={username}
          placeholder="Username"
          onChange={(ev) => {
            setUsername(ev.target.value.replace(/\s/g, ""));
            setError(false);
          }}
          error={error && !username}
          helperText={error && !username ? "Please enter username" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">
                  account_circle
                </span>
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={email}
          type="email"
          placeholder="Email"
          onChange={(ev) => {
            setEmail(ev.target.value);
            setError(false);
          }}
          error={error && !email}
          helperText={error && !email ? "Please enter Email" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span class="material-symbols-outlined">mail</span>
              </InputAdornment>
            ),
          }}
        />

        <SelectField
          id="PostType"
          value={interestType}
          onChange={(ev) => {
            setInterestType(ev.target.value);
          }}
          placeholder="Select a Topic"
          menuItems={menuItems}
          error={error && !interestType}
          helperText={error && !interestType ? "Please select a topic" : ""}
          startAdornment={
            <span className="material-symbols-outlined">topic</span>
          }
        />

        <TextInput
          value={password}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={(ev) => {
            setPassword(ev.target.value);
            setError(false);
          }}
          error={error && !password}
          helperText={error && !password ? "Please enter Password" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">lock</span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-outlined">
                    {showPassword ? "visibility_off" : "visibility"}
                  </span>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link to="/contact">Cant't Sign up?</Link>
        <Button
          endIcon={<SendIcon sx={{ color: "white" }} />}
          fullWidth
          variant="contained"
          type="submit"
        >
          Register
        </Button>
      </form>
    </>
  );
}
