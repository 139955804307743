import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { UserContext } from "../components/userContext";
import AlertComponent from "../components/alertBox";
import Loader from "../Ui/loader";

function UserProfile() {
  const { username } = useParams();
  const { userInfo } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const LOCAL_STORAGE_KEY = `userProfile-${username}`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${username}`
        );

        const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setUserData(parsedData.userData);
        }

        const fetchedUser = await response.json();
        setUserData(fetchedUser);

        const postResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/posts/user/${fetchedUser._id}`
        );
        const fetchedPosts = await postResponse.json();
        setUserPosts(fetchedPosts);

        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({ userData: fetchedUser, userPosts: fetchedPosts })
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username, LOCAL_STORAGE_KEY]);

  const handleDelete = async (postSlug) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmDelete) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/post/${postSlug}`, {
          method: "DELETE",
        });
        setUserPosts((prevPosts) =>
          prevPosts.filter((post) => post._id !== postSlug)
        );
      } catch (error) {
        setErrorMessage("Error deleting post:", error);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  const isOwner = userInfo?.username === username;

  return (
    <>
      <Helmet>
        <title>Profile {userData.username}</title>
      </Helmet>
      <AlertComponent status="error" message={errorMessage} />

      <div className="posts-container">
        <div className="user-posts">
          {userPosts.length > 1 && (
            <div
              style={{ justifyContent: "left" }}
              className="post-data-header"
            >
              <p>Posts uploaded</p>
              <span className="material-symbols-outlined">cloud</span>
            </div>
          )}
          {isOwner && userPosts.length === 0 && (
            <div className="no-post-ack">
              <p>You haven't posted anything yet.</p>
              <div className="create-post-ack">
                <Link to="/create">Create post</Link>
                <span className="material-symbols-outlined">add_circle</span>
              </div>
            </div>
          )}

          {!isOwner && userPosts.length === 0 ? (
            <div className="no-post-ack">
              <p>This author hasn't posted anything yet.</p>
            </div>
          ) : (
            userPosts.map((post) => (
              <div className="post-container" key={post._id}>
                <img alt="cover" src={post.cover}></img>

                <div className="text-container">
                  <Link
                    to={`/post/${post.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="post-title">{post.title}</p>
                  </Link>
                </div>
                {!isOwner && (
                  <div className="user-icons">
                    <Link to={`/post/${post.slug}`}>
                      <span className="material-symbols-outlined">
                        arrow_right_alt
                      </span>
                    </Link>
                  </div>
                )}
                {isOwner && (
                  <div className="user-icons">
                    <Link to={`/edit/${post.slug}`}>
                      <span className="material-symbols-outlined">edit</span>
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(post.slug);
                      }}
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </Link>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default UserProfile;
