import "./App.css";
import Layout from "./components/layout";
import IndexPage from "./pages/indexpage";
import LoginPage from "./pages/loginpage";
import RegisterPage from "./pages/registerpage";
import { Routes, Route } from "react-router-dom";
import { UserContextProvider } from "./components/userContext";
import CreatePost from "./pages/createpost";
import PostPage from "./pages/postpage";
import ContactPage from "./pages/contactpage";
import EditPost from "./pages/editpost";
import ReportPost from "./pages/reportpage";
import ChangePassword from "./pages/reset-password";
import UserProfile from "./pages/UserProfile";
import ProtectedRoute from "./components/ProtectedRoute";
import About from "./pages/about";
import Explore from "./pages/Explore";
import NotFound from "./pages/notFoundPage";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat', 'Open Sans', 'Roboto', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserContextProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<NotFound />}></Route>
            <Route index element={<IndexPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/ResetPassword" element={<ChangePassword />} />
            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <CreatePost />
                </ProtectedRoute>
              }
            />
            <Route path="/post/:slug" element={<PostPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/Explore" element={<Explore />} />
            <Route path="/edit/:slug" element={<EditPost />} />
            <Route path="/report" element={<ReportPost />} />
            <Route path="/user/:username" element={<UserProfile />} />
          </Route>
        </Routes>
      </UserContextProvider>
    </ThemeProvider>
  );
}

export default App;
