import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { UserContext } from "../components/userContext";
import Post from "../components/post";
import Footer from "../components/footer";
import Loader from "../Ui/loader";
import AlertComponent from "../components/alertBox";

const IndexPage = () => {
  const { posts, setPosts, page, setPage, hasMore, setHasMore } =
    useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const observer = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchPosts = useCallback(
    async (page) => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/post?page=${page}&limit=10`;
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.length < 10) {
          setHasMore(false);
        }

        setPosts((prevPosts) => {
          const existingIds = new Set(prevPosts.map((post) => post._id));
          const newPosts = data.filter((post) => !existingIds.has(post._id));
          return [...prevPosts, ...newPosts];
        });
      } catch (err) {
        console.error("Error fetching posts:", err);
        setErrorMessage("Failed to load posts. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [setPosts, setHasMore]
  );

  const lastPostElementRef = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, setPage]
  );

  useEffect(() => {
    if (page === 1 && posts.length === 0) {
      fetchPosts(1);
    }
  }, [page, posts.length, fetchPosts]);

  useEffect(() => {
    if (page > 1) {
      fetchPosts(page);
    }
  }, [page, fetchPosts]);

  const renderPosts = useMemo(() => {
    return posts.map((post, index) => {
      if (posts.length === index + 1) {
        return (
          <React.Fragment key={post._id}>
            <div ref={lastPostElementRef} className="grid-item">
              <Post {...post} />
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <div key={post._id} className="grid-item">
            <Post {...post} />
          </div>
        );
      }
    });
  }, [posts, lastPostElementRef]);

  return (
    <>
      {loading && <Loader />}
      <AlertComponent status="error" message={errorMessage} />

      <div className="grid-container">
        {posts.length > 0 && renderPosts}
        {page > 1 && null}
      </div>

      {!loading && posts.length > 0 && <Footer />}
    </>
  );
};

export default React.memo(IndexPage);
