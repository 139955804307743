import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Editor from "../components/Editor";
import { Helmet } from "react-helmet";
import AlertComponent from "../components/alertBox";
import Loader from "../Ui/loader";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FileUploadField from "../Ui/dropBox";
import SendIcon from "@mui/icons-material/Send";

export default function EditPost() {
  const { slug } = useParams();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [PostType, setPostType] = useState("");
  const [files, setFiles] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const menuItems = [
    { value: 10, label: "Business" },
    { value: 20, label: "News" },
    { value: 30, label: "Science" },
    { value: 40, label: "Technology" },
    { value: 50, label: "Entertainment" },
    { value: 60, label: "Sports" },
    { value: 70, label: "Health" },
    { value: 80, label: "Lifestyle" },
    { value: 90, label: "Travel" },
    { value: 100, label: "Food" },
    { value: 110, label: "Opinions" },
    { value: 120, label: "Gaming" },
  ];

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/post/`;
    fetch(url + slug).then((response) => {
      response.json().then((postInfo) => {
        setTitle(postInfo.title);
        setContent(postInfo.content);
        setSummary(postInfo.summary);
        setPostType(postInfo.PostType);
      });
    });
  }, [slug]);

  async function updatePost(ev) {
    ev.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    data.set("PostType", PostType);
    data.set("slug", slug);

    if (files?.[0]) {
      data.set("file", files?.[0]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update`, {
        method: "PUT",
        body: data,
        credentials: "include",
      });

      if (response.ok) {
        localStorage.removeItem(`post-${slug}`);
        setSuccessMessage("Post updated!");
        setRedirect(true);
      } else {
        setErrorMessage("Failed to update post");
      }
    } catch (error) {
      setErrorMessage("Network error. Please try again later.");
    } finally {
      setLoading(false);
    }
  }

  if (redirect) {
    return <Navigate to={"/post/" + slug} />;
  }

  return (
    <>
      <Helmet>
        <title>Update: {title}</title>
      </Helmet>
      {loading && <Loader />}
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="report" onSubmit={updatePost}>
        <h1>Update Content</h1>
        <TextField
          value={title}
          label="Title"
          fullWidth
          margin="dense"
          size="small"
          onChange={(ev) => {
            setTitle(ev.target.value);
          }}
        />

        <TextField
          value={summary}
          label="Summary"
          fullWidth
          margin="dense"
          size="small"
          onChange={(ev) => setSummary(ev.target.value)}
        />

        <Box sx={{ minWidth: 120 }}>
          <FormControl margin="dense" size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Topic</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={PostType}
              label="Topic"
              onChange={(ev) => {
                setPostType(ev.target.value);
              }}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.value} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FileUploadField
          type="file"
          accept="image/*"
          onChange={(ev) => {
            const file = ev.target.files[0];
            const maxSizeInMB = 2;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            const maxWidth = 1920;
            const maxHeight = 1080;

            if (!file) return;

            if (!file.type.startsWith("image/")) {
              setErrorMessage("Only image files are supported");
              window.scrollTo(0, 0);
              ev.target.value = "";
              return;
            }

            if (file.size > maxSizeInBytes) {
              setErrorMessage(
                `File size should be less than ${maxSizeInMB} MB`
              );
              window.scrollTo(0, 0);
              ev.target.value = "";
              return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
              if (img.width > maxWidth || img.height > maxHeight) {
                setErrorMessage(
                  `Image dimensions should be within ${maxWidth}x${maxHeight}px`
                );
                window.scrollTo(0, 0);
                ev.target.value = "";
              } else {
                setFiles(ev.target.files);
                setSuccessMessage("Image Accepted");
                window.scrollTo(0, 0);
              }
            };
          }}
        />

        <Editor onChange={setContent} value={content} />
        <Button
          endIcon={<SendIcon sx={{ color: "white" }} />}
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          type="submit"
        >
          Post
        </Button>
      </form>
    </>
  );
}
