import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-quill/dist/quill.snow.css";
import Editor from "../components/Editor";
import AlertComponent from "../components/alertBox";
import Loader from "../Ui/loader";
import TextInput from "../Ui/input";
import FormControl from "@mui/material/FormControl";
import FileUploadField from "../Ui/dropBox";
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "code-block",
];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["link", "image"],
    ["clean"],
    ["bold", "italic", "underline", "strike"],
    ["code-block"],
  ],
  syntax: true,
};

export default function CreatePost() {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [PostType, setPostType] = useState("");
  const [files, setFiles] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const menuItems = [
    { value: 10, label: "Business" },
    { value: 20, label: "News" },
    { value: 30, label: "Science" },
    { value: 40, label: "Technology" },
    { value: 50, label: "Entertainment" },
    { value: 60, label: "Sports" },
    { value: 70, label: "Health" },
    { value: 80, label: "Lifestyle" },
    { value: 90, label: "Travel" },
    { value: 100, label: "Food" },
    { value: 110, label: "Opinions" },
    { value: 120, label: "Gaming" },
  ];

  const updateSummary = (ev) => {
    const inputSummary = ev.target.value;
    const limitedSummary = inputSummary.split(" ").slice(0, 30).join(" ");
    setSummary(limitedSummary);
    setError(false);
  };

  const handleKeyDown = (event) => {
    const maxWords = 5;
    const words = event.target.value.split(" ");
    if (
      words.length >= maxWords &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const createNewPost = async (ev) => {
    const data = new FormData();

    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    data.set("PostType", PostType);
    data.set("file", files[0]);

    ev.preventDefault();
    if (!title || !summary) {
      setError(true);
      setErrorMessage("Please fill out all the fields.");

      return;
    }
    setError(false);
    setErrorMessage("");
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/post`;
      const response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });

      if (response.ok) {
        setRedirect(true);
        setSuccessMessage("Post created");
      } else {
        setErrorMessage("Failed to create post");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Network error. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (redirect) {
        <Navigate to="/" replace />;
        window.location.href = "/";
        window.scrollTo(0, 0);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [redirect]);

  return (
    <>
      <Helmet>
        <title>Create Post</title>
      </Helmet>
      {loading && <Loader />}
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="report" onSubmit={createNewPost}>
        <h1>Create Content</h1>
        <TextInput
          value={title}
          label="Title"
          onChange={(ev) => {
            setTitle(ev.target.value);
            setError(false);
          }}
          error={error && !title}
          helperText={error && !title ? "Please enter title" : ""}
        />

        <TextInput
          value={summary}
          label="Brief"
          onChange={updateSummary}
          onKeyDown={handleKeyDown}
          error={error && !summary}
          helperText={error && !summary ? "Please enter summary" : ""}
        />

        <Box sx={{ minWidth: 120 }}>
          <FormControl margin="dense" size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Topic</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={PostType}
              label="Topic"
              onChange={(ev) => {
                setPostType(ev.target.value);
                setError(false);
              }}
              error={error && !PostType}
              helperText={
                error && !PostType ? "Please select a Post category" : ""
              }
            >
              {menuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FileUploadField
          type="file"
          accept="image/*"
          onChange={(ev) => {
            const file = ev.target.files[0];
            const maxSizeInMB = 2;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            const maxWidth = 1920;
            const maxHeight = 1080;

            if (!file) return;

            if (!file.type.startsWith("image/")) {
              setErrorMessage("Only image files are supported");
              window.scrollTo(0, 0);
              ev.target.value = "";
              return;
            }

            if (file.size > maxSizeInBytes) {
              setErrorMessage(
                `File size should be less than ${maxSizeInMB} MB`
              );
              window.scrollTo(0, 0);
              ev.target.value = "";
              return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
              if (img.width > maxWidth || img.height > maxHeight) {
                setErrorMessage(
                  `Image dimensions should be within ${maxWidth}x${maxHeight}px`
                );
                window.scrollTo(0, 0);
                ev.target.value = "";
              } else {
                setFiles(ev.target.files);
                setSuccessMessage("Image Accepted");
                window.scrollTo(0, 0);
              }
            };
          }}
        />

        <Editor
          value={content}
          theme="snow"
          onChange={(newValue) => setContent(newValue)}
          modules={modules}
          formats={formats}
        />

        <Button
          endIcon={<SendIcon sx={{ color: "white" }} />}
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          type="submit"
        >
          Post
        </Button>

        <div className="contact-div">
          <p>
            No explicit or offensive material, support for illegal activities,
            or piracy links. Respect user privacy, engage in civil
            communication, and report violations for a positive community.
            Violations may lead to content removal or account actions.
          </p>
        </div>
      </form>
    </>
  );
}
