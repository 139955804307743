import { useState } from "react";
import AlertComponent from "./alertBox";
import Loader from "../Ui/loader";
import TextInput from "../Ui/input";
import { Button, InputAdornment } from "@mui/material";
import TextArea from "../Ui/textArea";
import SendIcon from "@mui/icons-material/Send";

export default function Feedback() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !query) {
      setError(true);
      setErrorMessage("Please fill out all the fields.");
      return;
    }
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/contact`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, query }),
      });

      if (response.ok) {
        setSuccessMessage("Thanks for your feedback!");
        setName("");
        setEmail("");
        setQuery("");
        setError(false);
      } else {
        setErrorMessage("Please fill out all the fields.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {successMessage && (
        <AlertComponent status="success" message={successMessage} />
      )}
      {errorMessage && <AlertComponent status="error" message={errorMessage} />}

      <form className="feedback" onSubmit={handleSubmit}>
        <h4>Feedback</h4>
        <hr></hr>
        {loading && <Loader />}
        <TextInput
          value={name}
          placeholder="Name"
          onChange={(ev) => {
            setName(ev.target.value);
            setError(false);
          }}
          error={error && !name}
          helperText={error && !name ? "Please enter your name" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">
                  account_circle
                </span>
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={email}
          placeholder="Email@gmail.com"
          onChange={(ev) => {
            setEmail(ev.target.value);
            setError(false);
          }}
          error={error && !email}
          helperText={error && !email ? "Please enter your email" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">mail</span>
              </InputAdornment>
            ),
          }}
        />

        <TextArea
          value={query}
          placeholder="Message"
          onChange={(ev) => {
            setQuery(ev.target.value);
            setError(false);
          }}
          error={error && !query}
          helperText={error && !query ? "Please enter a message" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="material-symbols-outlined">comment</span>
              </InputAdornment>
            ),
          }}
        />

        <Button
          style={{ marginTop: "5px" }}
          type="submit"
          variant="contained"
          endIcon={<SendIcon sx={{ color: "white" }} />}
          fullWidth
        >
          Send
        </Button>
      </form>
    </>
  );
}
